import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { Web3ReactProvider } from '@web3-react/core'

import { store, persistor } from './store';

import './i18n'
import Loading from './Loading';
import { ethers } from 'ethers';

function getLibrary(provider: any) {
  const library = new ethers.providers.Web3Provider(provider)
  return library
}

const LazyApp = React.lazy(() => import('./components/AppLayout'));

function App() {
  console.info(`VERSION: ${process.env.REACT_APP_UPDATED}`)
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <I18nextProvider i18n={i18next}>
            <Suspense fallback={<Loading />}>
              <LazyApp />
            </Suspense>
          </I18nextProvider>
        </Web3ReactProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
