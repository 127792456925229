/* eslint-disable import/no-anonymous-default-export */
import produce from "immer"

import { FETCH_USER_SUCCESS } from '../actions/types'
const initialState = {
    data: []
};
export default produce((state = initialState, action: { type: any; payload: any; }) => {
    const { type, payload } = action;
    switch (type) {
        case FETCH_USER_SUCCESS:
            state.data = payload
            break
        default:
            break
    }
    return state
})