import { combineReducers } from "redux";
import message from './message'
import auth from './auth'
import resort from './resort'
import user from './user'
import roomType from './roomType'
import reservations from './reservations'
import wallet from './wallet'

export default combineReducers({
    message,
    auth,
    resort,
    user,
    roomType,
    reservations,
    wallet
});