import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
} from "../actions/types";
import produce from "immer"

const user = JSON.parse(localStorage.getItem("user") || 'null');

const initialState = user
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null };

// eslint-disable-next-line import/no-anonymous-default-export
export default produce((state = initialState, action: { type: any; payload: any; }) => {
    const { type, payload } = action;

    switch (type) {
        case LOGIN_SUCCESS:
            state.isLoggedIn = true;
            state.user = payload.user
            break
        case LOGIN_FAIL:
            state.user = null
            state.isLoggedIn = false
            break
        case LOGOUT:
            state.isLoggedIn = false
            state.user = null
            break
        default:
            break
    }

    return state
})