/* eslint-disable import/no-anonymous-default-export */
import produce from "immer"
import { FETCH_ROOM_TYPE_SUCCESS, START_FETCH_ROOM_TYPE, FETCH_ROOM_TYPE_FAIL } from '../actions/types'
const initialState = {
    data: [],
    loading: false,
    error: null
};
export default produce((state = initialState, action: { type: any; payload: any; }) => {
    const { type, payload } = action;
    switch (type) {
        case FETCH_ROOM_TYPE_SUCCESS:
            state.loading = false;
            state.data = payload
            break
        case START_FETCH_ROOM_TYPE:
            state.loading = true
            break
        case FETCH_ROOM_TYPE_FAIL:
            state.loading = false
            state.error = payload
            break
        default:
            break
    }
    return state
})