import React from 'react'
import './loading.css'
type LoaderProps = {
    style?:React.CSSProperties
}
export const Loader = (props:LoaderProps) => <div className="loader" style={props.style}></div>
export default () => {
    return <div className="overlay">
        <div className="loader"></div>
    </div>
}