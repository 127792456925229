/* eslint-disable import/no-anonymous-default-export */
import produce from "immer"

import { FETCH_NFT_START, FETCH_NFT_SUCCESS, FETCH_NFT_FAIL } from '../actions/types'
const initialState = {
    nfts: [],
    loading: false,
    error: null
};
export default produce((state = initialState, action: { type: any; payload: any; }) => {
    const { type, payload } = action;
    switch (type) {
        case FETCH_NFT_START:
            state.loading = true
            break
        case FETCH_NFT_SUCCESS:
            state.loading = false
            state.nfts = payload
            break
        case FETCH_NFT_FAIL:
            state.loading = false
            state.error = payload
            state.nfts = []
            break
        default:
            break
    }
    return state
})