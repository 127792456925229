/* eslint-disable import/no-anonymous-default-export */
import produce from "immer"
import { FETCH_RESERVATIONS_SUCCESS, START_ETCH_RESERVATIONS, FETCH_RESERVATIONS_FAIL } from '../actions/types'

const initialState = {
    data: [],
    loading: true,
    error: null
};
export default produce((state = initialState, action: { type: any; payload: any; }) => {
    const { type, payload } = action;
    switch (type) {
        case FETCH_RESERVATIONS_SUCCESS:
            state.data = payload
            state.loading = false
            break
        case START_ETCH_RESERVATIONS:
            state.loading = true;
            break
        case FETCH_RESERVATIONS_FAIL:
            state.loading = false;
            state.error = payload
            break
        default:
            break
    }
    return state
})