export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const FETCH_RESORT_START = "FETCH_RESORT_START"
export const FETCH_RESORT_SUCCESS = "FETCH_RESORT_SUCCESS"
export const FETCH_RESORT_FAIL = "FETCH_RESORT_FAIL"
export const NEW_RESORT_SUCCESS = "NEW_RESORT_SUCCESS"
export const NEW_RESORT_FAIL = "NEW_RESORT_FAIL"

export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS"

export const FETCH_ROOM_TYPE_SUCCESS = "FETCH_ROOM_TYPE_SUCCESS"
export const FETCH_ROOM_TYPE_FAIL = "FETCH_ROOM_TYPE_FAIL"
export const START_FETCH_ROOM_TYPE = "START_FETCH_ROOM_TYPE"

export const FETCH_RESERVATIONS_SUCCESS = "FETCH_RESERVATIONS_SUCCESS"
export const FETCH_RESERVATIONS_FAIL = "FETCH_RESERVATIONS_FAIL"
export const START_ETCH_RESERVATIONS = "START_ETCH_RESERVATIONS"


export const FETCH_NFT_START = "FETCH_NFT_START"
export const FETCH_NFT_SUCCESS = "FETCH_NFT_SUCCESS"
export const FETCH_NFT_FAIL = "FETCH_NFT_FAIL"
